#structure-login {
    position: absolute;
    display: flex;
    align-items: center;
    padding-top: 50px;
    flex-direction: column;
    overflow-y: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-repeat: repeat-y;
    background-size: cover;
    background-position-x: center;
}

#container-login {
    background-color: white;
    background-color: #ffffffa6;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 7px #00bbaa;
    margin: 35px 0 50px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 275px;
}


.divisor {
    display: inline-flex;
    align-items: center;
    margin: 22px 0;
    width: 100%;
    gap: 10px;
}

.divisor > div{
    background-color: #999999;
    height: 1px;
    width: 100%;
}

.divisor > p {
    color: rgb(63, 63, 63);
    font-size: 0.9rem;
}

#container-login input {
    height: 30px;
    margin: 0 0 10px 0;
    border: 1px solid #868600;
    border-radius: 5px;
    padding: 0 7px;
}

#container-login label {
    margin: 0 0 4px 0;  
}


#container-login h3 {
    margin: 0 0 20px 0;
    color: black;
}

#logo-scrollb-login img {
    max-width: 100%;
    max-height: 100px;
}

/* Sign In Form */
#container-login-userpassword {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.btn-signin {
    padding: 9px 0px;
    border: none;
    background-color: #008075;
    border-radius: 5px;
    color: white;
    font-weight: bold;
    margin: 10px 0 20px 0;
    cursor: pointer;
    font-size: 0.9rem;
}

.btn-newuser {
    padding: 9px 0px;
    border: none;
    background-color: green;
    border-radius: 5px;
    color: white;
    font-weight: bold;
    cursor: pointer;
    font-size: 0.9rem;
}

.btn-forgot {
    margin: 10px 0 0 0;
}


/* Register Form */
#container-login-newuser {
    display: flex;
    flex-direction: column;
    width: 90%;

}

.requirements-caption {
    display: inline-flex;
    align-items: center;
    gap: 2px;
    font-size: 0.8rem;
    font-weight: bold;
    margin: 0 0 5px 0;
}

.btn-createuser {
    padding: 9px 5px;
    border: none;
    background-color: green;
    border-radius: 5px;
    color: white;
    font-weight: bold;
    cursor: pointer;
    font-size: 0.9rem;
    margin: 10px 0 0 0;
}

.btn-login-goback {
    height: 30px;
    width: fit-content;
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    border: none;
    background-color: unset;
    cursor: pointer;
    font-weight: bold;
    margin: 0 0 10px 0;
    color: #454545;
    fill: #454545;
}

.btn-login-goback .i-goback {
    height: 20px;
    width: 20px;
}

/* Smartphones */
@media only screen and (max-width: 600px) {
    .btn-signin {
        padding: 12px 0px;
    }

    #container-login input {
        height: 40px;
    }

    .btn-newuser {
        padding: 12px 0px;
    }
    
    .btn-createuser {
        padding: 12px 0px;
    }

}