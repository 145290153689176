#settings-container {
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 5px;
    top: calc(100% + 10px);
    left: 50%;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    z-index: 9999;
    background-color: #ffffff;
    box-shadow: 0px 0px 6px 0px black;
    -webkit-box-shadow: 0px 0px 6px 0px black;
    /* border: 2px solid silver; */
    border-radius: 4px;
}

.s-b-icondiv {
    display: inline-flex;
    align-items: center;
    gap: 10px;
    font-size: 1rem;
    text-wrap: nowrap;
    white-space: nowrap;
    padding: 5px;
    cursor: pointer;

}

.s-icon {
    height: 25px;
    width: 25px;
    flex: none;
}

#share-container {
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 5px;
    top: calc(100% + 10px);
    left: 50%;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    z-index: 9999;
    background-color: #ffffff;
    box-shadow: 0px 0px 6px 0px black;
    -webkit-box-shadow: 0px 0px 6px 0px black;
    /* border: 2px solid silver; */
    border-radius: 4px;
}

.qrcode-container-share {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 10px 0;
}

.qrcode-share {
    height: 100px;
    width: 100px;
    border-radius: 5px;
}