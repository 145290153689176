/* HTML: <div class="loader"></div> */
.loader {
        width: 50px;
        aspect-ratio: 1;
        border-radius: 50%;
        border: 8px solid;
        border-color: #000 #0000;
        animation: l1 1s infinite;
        -webkit-animation: l1 1s infinite;
      }
      @keyframes l1 {to{transform: rotate(.5turn)}}
      @-webkit-keyframes l1 {to{-webkit-transform: rotate(.5turn)}}


.loader-div-modal {
  background-color: #0000006b;
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
  position: fixed;
  top: 50px;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 300;
}
.loader-white {
        width: 50px;
        aspect-ratio: 1;
        border-radius: 50%;
        border: 8px solid;
        border-color: #000 #0000;
        animation: l1 1s infinite;
        -webkit-animation: l1 1s infinite;
      }
      @keyframes l1 {to{transform: rotate(.5turn)}}
      @-webkit-keyframes l1 {to{-webkit-transform: rotate(.5turn)}}


.loader-div-modal-white {
  background-color: #d4d4d496;
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
  position: fixed;
  top: 50px;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 300;
}
