#myposts-actions {
    margin: 5px 0 15px 15px;
}

#btn-neuer-beitrag {
    border: none;
    background-color: #008dda;
    font-weight: bold;
    color: white;
    border-radius: 5px;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    fill: white;
    padding: 8px 14px 8px 5px
}



#myposts-captions {
    display: flex;
    flex-direction: row;
    font-weight: bold;
    padding: 0 0 0 5px;
    margin: 0 15px 0 15px;
    max-width: 1019px; /* - 20px padding */
    height: 55px;
    align-items: center;
    background-color: #c3dadb;
    border-bottom: 1px solid #1f596b;
    gap: 15px;
}

#myposts-captions > p {
    overflow: hidden;
    text-overflow: ellipsis;
}

#myposts-captions .status {
    flex: 0.2 1;
}

#myposts-captions .pin {
    flex: 0.3 1;
}

#myposts-captions .title {
    flex: 2 1;
}


#myposts-container {
    display: flex;
    flex-direction: column;
    gap: 2px;
    max-width: 1024px;
    overflow-y: auto;
    max-height: calc(100% - 70px);
    margin: 0 15px 0 15px;
}

.myposts-item {
    background-color: #ffffff;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 0px 0px 5px;
    height: 55px;
    gap: 15px;
}

.myposts-item-blank {
    background-color: white;
    padding: 20px;
}

.myposts-item-title {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    flex: 2 1;
}

.myposts-item-id {
    flex: 0.3 1;
    text-overflow: ellipsis;
    overflow: hidden;
}

.myposts-item-active {
    flex: 0.2 1;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.enter-icon-ubersicht {
    flex-shrink: 0;
    fill: #636363;
}

.inactive-icon {
    fill: #910000;
}

.active-icon {
    fill: #006700;
}

.active-container {
    background-color: #cff1d2;
    color: green;
    font-size: 0.8rem;
    /* padding: 5px 10px 5px 10px; */
    border-radius: 5px;
    display: inline-flex;
    align-items: center;
    gap: 6px;
    padding: 0px 8px;
    height: 25px;
}

.active-point {
    background-color: green;
    border-radius: 100%;
    height: 5px;
    width: 5px;
    box-shadow: 0px 0px 4px 0px green;
    -webkit-box-shadow: 0px 0px 4px 0px green;
}
.inactive-container {
    background-color: #f1cfcf;
    color: #9d0a0a;
    font-size: 0.8rem;
    /* padding: 5px 10px 5px 10px; */
    border-radius: 5px;
    display: inline-flex;
    align-items: center;
    gap: 6px;
    padding: 0px 8px;
    height: 25px;
}

.inactive-point {
    background-color: rgb(128, 0, 0);
    border-radius: 100%;
    height: 5px;
    width: 5px;
    box-shadow: 0px 0px 4px 0px rgb(128, 0, 0);
    -webkit-box-shadow: 0px 0px 4px 0px rgb(128, 0, 0);
}


@media only screen and (max-width: 600px) {
    .myposts-item-title {
        flex: 0.8 1;
    }

    #myposts-captions .title {
        flex: 0.8 1;
    }
}

