#container-instructions {
    position: absolute;
    bottom: 91px;
    right: 45px;
    height: 188px;
    width: 329px;
    z-index: 199;
}

#pfeil {
    height: 100%;
    width: 100%;
    filter: invert(1) drop-shadow(0px 0px 2px black);
    mix-blend-mode: difference;
}