#container-blur-msgbox {
    position: fixed;
    bottom: 0;
    height: 100dvh;
    left: 0;
    right: 0;
    backdrop-filter: blur(4px);

    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10000;
}

#s-c-msgbox {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    background-color: white;
    font-size: 1.2rem;
    font-weight: bold;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 2px 2px 4px black;
    -webkit-box-shadow: 2px 2px 4px black;
    max-width: 80%;
}

#c-msgbox-buttons {
    display: inline-flex;
    gap: 15px;
}

#msgbox-confirm {
    height: 35px;
    min-width: 110px;
    border: none;
    background-color: #0099ff;
    border-radius: 5px;
    color: white;
    font-weight: bold;
    font-size: 1.1rem;
    box-shadow: 2px 2px 4px black;
    -webkit-box-shadow: 2px 2px 4px black;
    cursor: pointer;
}

#msgbox-cancel {
    height: 35px;
    min-width: 110px;
    background-color: white;
    border: none;
    border-radius: 5px;
    color: black;
    font-weight: bold;
    font-size: 1.1rem;
    box-shadow: 2px 2px 4px black;
    -webkit-box-shadow: 2px 2px 4px black;
    cursor: pointer;

}