#structure-slideview-info {
    background-color: white;
    padding: 20px;
    height: calc(100% - 40px);
    overflow-y: hidden;
}

#container-likes-views {
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin: 0 0 20px 0;
}

#view-counter-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 1.3rem;
    font-weight: bold;
}

#likes-counter-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 1.3rem;
    font-weight: bold;
}

#container-questions-list {
    overflow-y: hidden;
    height: calc(100% - 100px);
}

#questions-list {
    display: flex;
    /* flex-direction: column; */
    flex-direction: row;
    flex-wrap: wrap;
    row-gap: 15px;
    column-gap: 20px;
    align-content: flex-start;

    /* gap: 10px; */
    margin: 15px 0 0 10px;
    padding-left: 10px;
    overflow-y: scroll;
    height: calc(100% - 66px);
}

.question-bubble {
	position: relative;
	max-width: calc(100% - 45px);
    width: max-content;
    background-color: #56bd70;
	padding: 10px 17px;
	font-size: 1rem;
	border-radius: 10px;
}

.question-bubble::before {
	content: '';
	position: absolute;
	left: 0;
	top: 50%;
	width: 0;
	height: 0;
	border: 23px solid transparent;
	border-right-color: #56bd70;
	border-left: 0;
	border-top: 0;
	margin-top: -5px;
	margin-left: -10px;
}

.button-view-verwaltung {
    fill: rgb(7, 132, 0);
}

.button-like-verwaltung {
    fill: rgb(203, 0, 0);
}

.button-questions-verwaltung {
    fill: rgb(207, 196, 0);
}