
#nav-btn-goback {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #0086ff;
    padding: 3px 5px 3px 0;
    cursor: pointer;
    width: fit-content;
    margin: 0 0 0 5px;
}

.back-icon {
    height: 26px;
    width: 26px;
    fill: #0086ff;
    transform: translate(6px, 0px);
    -webkit-transform: translate(6px, 0px);
}


/* new post */
#nav-newpost-container {
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 20px 0;
    width: 100%;
    position: fixed;
    top: 60px;
    left: 0px;
    right: 0px;
    height: 45px;

}

#newpost-btn-save {
    color: #ffffff;
    font-weight: bold;
    margin: 0 15px 0 0;
    padding: 8px 10px 8px 6px;
    cursor: pointer;
    border: none;
    border-radius: 7px;
    background-color: #0086ff;
    display: inline-flex;
    align-items: center;
}

#newpost-btn-save .save-icon {
    fill: white;
    height: 25px;
    width: 25px;
}

#newpost-btn-cancel {
    color: #ffffff;
    font-weight: bold;
    margin: 0 15px 0 0;
    padding: 8px 10px 8px 6px;
    cursor: pointer;
    border: none;
    border-radius: 7px;
    background-color: #703232;
    display: inline-flex;
    align-items: center;
}


/* post view */
#nav-posts-container {
    width: 100%;
    max-width: 1024px;
    padding: 5px 0;
    margin: 0 0 20px 0;
    display: grid;
    grid-template-columns: repeat(3, auto);
    grid-template-rows: repeat(2, auto);
    grid-template-areas: 
        "back none next"
        "title title status";
}

#nav-posts-title-wrapper {
    width: calc(100% - 30px);
    margin: 5px 0 0 15px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    grid-area: title;
}

#nav-posts-title-wrapper > p {
    max-width: 100%;
    font-size: 1.7rem;
    font-weight: 400;
}

.icon-share {
    padding: 5px;
    border-radius: 100%;
    cursor: pointer;
    flex-shrink: 0;
}

.icon-settings {
    padding: 5px;
    border-radius: 100%;
    flex-shrink: 0;
}

.n-p-btn {
    border: none;
    align-items: center;
    display: inline-flex;
    justify-content: center;
    cursor: pointer;
    padding: 2px 10px 2px 0px;
    border-radius: 5px;
    font-size: 0.85rem;
}

.n-p-btn:hover {
    background-color: rgb(214, 214, 214);
}

#container-status {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    font-size: 0.85rem;
    grid-area: status;
    justify-self: flex-end;
    margin: 0 5px 0 0;
}

#container-status > div {
    display: inline-flex;
}

#switch-status {
    display: none;
    height: 0;
    width: 0;
}

.label-switch {
    cursor: pointer;
    height: 25px;
    width: 50px;
    background-color: #f05b5b;
    border-radius: 4px;
    display: block;
    position: relative;
    box-shadow: inset 0px 0px 9px -4px #000000;
    transition: background-color 0.2s;
}

.label-switch::after {
    position: absolute;
    top: 2px;
    left: 2px;
    content: '';
    height: 21px;
    width: 23px;
    border-radius: 3px;
    background-color: white;
    transition: transform 0.15s cubic-bezier(0.35, 1.32, 0.99, 1.01);
    box-shadow: inset 0 0 1px 0px #8f8f8f;
}

#switch-status:checked + .label-switch {
    background-color: #0eab0e;;
}

#switch-status:checked + .label-switch::after {
    transform: translateX(100%);
}



/* slide view */

#nav-slides-container {
    width: 100%;
    padding: 5px 0;
    margin: 0 0 20px 0;
    display: grid;
    grid-template-columns: repeat(3, auto);
    grid-template-rows: repeat(2, auto);
    grid-template-areas: 
        "back none next"
        "title title title";
}

#nav-slides-title-wrapper {
    width: calc(100% - 30px);
    overflow: hidden;
    margin: 5px 0 0 15px;
    display: inline-flex;
    gap: 10px;
    align-items: center;
    grid-area: title;
}

#nav-slides-title-wrapper > p {
    max-width: 100%;
    font-size: 1.7rem;
    font-weight: 400;
}

#nav-btn-next {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #0086ff;
    padding: 3px 0 3px 5px;
    cursor: pointer;
    width: fit-content;
    margin: 0 5px 0 0;
    justify-self: flex-end;
}

.next-icon {
    height: 26px;
    width: 26px;
    fill: #0086ff;

    grid-area: next;
    transform: scale(-1,1) translate(7px);
    -webkit-transform: scale(-1,1) translate(7px);
}


    /* Smartphones */
@media only screen and (max-width: 600px) {
    #nav-posts-title-wrapper {
        align-items: flex-start;
        flex-direction: column;
    }

    #container-status {
        flex-direction: column;
    }

}