#newpost-structure {
  display: flex;
  flex-direction: column;
  padding: 20px;
  max-width: 100%;
  max-height: calc(100% - 100px);
  overflow-y: auto;
  overflow-x: hidden;
  background-color: white;
  margin-top: 60px;
}



#newpost-container-top > div > input {
  height: 30px;
  font-size: 0.9rem;
  border: 1px solid #656565;
  border-radius: 3px;
}

#newpost-structure label {
  margin: 0 0 5px 0;
  font-size: 1rem;
  display: inline-flex;
}

/* Data top Container`s */
  #newpost-container-top {
    display: inline-flex;
    align-items: self-start;
    gap: 20px;
    flex-wrap: wrap;
  }


  .container-new-title {
    display: flex;
    flex-direction: column;
  }
  .container-new-id {
    display: flex;
    flex-direction: column;
  }

  #title {
    margin: 0 0 10px 0;
    min-width: 200px;
  }
  
  #id {
    width: 70px;
  }

  /* File input */
    .container-new-fileinput {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      align-items: flex-start;
      width: fit-content;
    }

    .container-new-fileinput > label {
      align-self: flex-start;
    }

    
    #new-wallpaper {
      background-color: #0086ff;
      height: 4em;
      padding: 0 5px;
      align-items: center;
      display: inline-flex;
      border-radius: 5px;
      cursor: pointer;
    }

    .icon-new-wallpaper {
      height: 35px;
      width: 35px;
      fill: white;
    }

    #wallpapers {
      display: inline-flex;
      align-items: center;
      gap: 10px;
    }

    .wallpaper-item {
      border-radius: 5px;
      height: 4em;
      aspect-ratio: 9/16;
      cursor: pointer;
      border: 2px solid transparent;
      object-fit: cover;
    }

    .wallpaper-item:hover {
      border: 2px solid silver;
    }

   

/* End containers top */



/* Slides */
  
  #newslides-container {
    display: inline-flex;
    padding: 10px 0 0px 0;
    gap: 20px;
    height: calc(100% - 10px);
    flex-wrap: nowrap;
  }

  #newslides-preview {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 20px;
    max-width: 100%;
    overflow-x: auto;
    padding: 10px;
    /* height: calc(100% - 20px); */
  }

    /* scrollbar */
      #newslides-preview::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        border-radius: 10px;
        background-color: #F5F5F5;
      }
      #newslides-preview::-webkit-scrollbar {
        width: 12px;
        background-color: #F5F5F5;
      }
      #newslides-preview::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
        background-color: #555;
      }

    /* end scrollbar */

  #newslide-new {
    background-color: beige;
    aspect-ratio: 9/16;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 60vh;   
    position: relative;
    border-radius: 5px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    margin: 10px 0 0 0;
  }

  #newslide-new-caption {
    position: absolute;
    top: 10px;
    font-weight: bold;
    mix-blend-mode: difference;
    color: white;
  }

  #newslide-slide {
    background-color: rgb(189, 212, 180);
    aspect-ratio: 9/16;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 55vh;
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 5px;
  }

  #newslide-slide > p {
    text-align: center;
    padding: 0 0.4em;
  }

  #newslide-btnnew {
    position: absolute;
    bottom: 10px;
    right: 10px;
    display: inline-flex;
    align-items: center;
    padding: 6px 14px 6px 10px;
    border-radius: 10px;
    border: none;
    background-color: white;
    box-shadow: 2px 2px 3px 0px black;
    cursor: pointer;
    
  }

  #newslide-vers {
    background-color: #ffffff5c;
    border: 1px dotted black;
    border-radius: 5px;
    text-align: center;
    font-family: unset;
    resize: auto;
    max-width: calc(100% - 30px);
    min-width: calc(100% - 30px);
    min-height: 60px;
    max-height: 130px;
  }

  #newslide-font-size {
    background-color: #ffffffb5;
    padding: 7px 15px;;
    display: flex;
    flex-direction: column;
    gap: 3px;
    width: 100px;
    margin: 15px 0;
    border-radius: 10px;
    align-items: center;

  }

  #newslide-font-size > div {
    display: inline-flex;
    align-items: center;
    gap: 5px;
  }

  #newslide-font-size > div > button {
    height: 30px;
    width: 30px;
    background-color: #81b1ae;
    border: none;
    border-radius: 5px;
    font-size: 1.1rem;
    font-weight: bold;
    cursor: pointer;
  }

  #newslide-font-color {
    background-color: #ffffffb5;
    padding: 7px 15px;;
    display: flex;
    flex-direction: column;
    gap: 3px;
    width: 100px;
    border-radius: 10px;
    align-items: center;
    
  }

  #font-size-ns {
    height: 25px;
    width: 40px;
    text-align: center;
    background-color: #ffffffab;
    border: 1px solid silver;
    border-radius: 5px;
  }

  #font-color-ns {
    height: 30px;
    width: 50px;
    cursor: pointer;
  }

  .slide-edit-btn {
    position: absolute;
    bottom: 10px;
    right: 10px;
    height: 36px;
    width: 36px;
    padding: 0;
    border: none;
    border-radius: 5px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: white;
    box-shadow: 0px 0px 3px black;
  }

  .slide-remove-btn {
    position: absolute;
    bottom: 10px;
    right: 56px;
    height: 36px;
    width: 36px;
    padding: 0;
    border: none;
    border-radius: 5px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: white;
    box-shadow: 0px 0px 3px black;
  }

  .newslide-icon-delete {
    height: 32px;
    width: 32px;
    fill: #890000;
  }

  .newslide-icon-edit {
    height: 32px;
    width: 32px;
  }

  .modal-edit-slide-container {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    backdrop-filter: blur(3px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #00000054;
    border-radius: 5px;
  }

  .newslide-icon-save {
    height: 24px;
    width: 24px;
  }

  #newslide-btn-save {
    position: absolute;
    bottom: 10px;
    right: 10px;
    display: inline-flex;
    align-items: center;
    padding: 6px 14px 6px 10px;
    border-radius: 10px;
    border: none;
    background-color: white;
    box-shadow: 2px 2px 3px 0px black;
    cursor: pointer;
  }

  #newslide-btn-cancel {
    position: absolute;
    bottom: 10px;
    left: 10px;
    display: inline-flex;
    align-items: center;
    padding: 6px 14px 6px 10px;
    border-radius: 10px;
    border: none;
    background-color: rgb(248 73 67);
    box-shadow: 2px 2px 3px 0px black;
    cursor: pointer;
    height: 36px;
  }

/* End Slides */

.tooltip-newslide {
  position: absolute;
  right: 10px;
  top: 10px;
}


@media only screen and (max-width: 600px) {
  #button-save {
    bottom: 20px;
    right: 20px;
    left: unset;
  }

  #newslides-container {
    flex-wrap: wrap;
  }

  #newslides-preview {
    order: 1;
  }

  #newslide-new {
    order: 0;
    height: 50vh;
  }
}

