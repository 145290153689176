#navbar-verwaltung {
    height: 50px;
    position: fixed;
    background-color: rgb(29, 29, 29);
    top: 0;
    right: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    z-index: 101;
}


.navbar-container-left {
    display: inline-flex;
    align-items: center;
    gap: 10px;
    font-size: 0.9rem;
}
.user-container {
    height: 100%;
    display: flex;
    flex-direction: row;
    color: white;
    align-items: center;
    gap: 10px;
    position: relative;
    cursor: pointer;
}

.user-container:hover {
    background-color: rgb(43, 43, 43);
}

.user-photo {
    height: calc(100% - 10px);
    border-radius: 100%;
    margin: 0 10px;
    aspect-ratio: 1 / 1;
}

.user-displayname {
    font-size: 1.1rem;
    width: fit-content;
    color: white;
    font-size: 1.1rem;
}

#logout-container {
    position: absolute;
    top: 55px;
    right: 5px;
    padding: 10px;
    background-color: rgb(61, 61, 61);
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    cursor: pointer;
}

#logout-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: rgb(179, 179, 179);
    border-radius: 5px;
    padding: 5px 10px;
    cursor: pointer;
}

#menu-icon {
    fill: white;
    padding: 5px;
    height: 40px;
    width: 30px;
    cursor: pointer;
}