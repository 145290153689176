
#structure-admin {
    padding: 10px 0 20px 0;
    background-color: #eeeeee;
    position: absolute;
    top: 50px;
    left: 0;
    right: 0;
    height: calc(100% - 80px);
    display: flex;
    flex-direction: column;
    overflow-y: hidden;
}


