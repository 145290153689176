#datenschutz {
    text-decoration: none;
    color: black;
    margin: 0 0 20px 0;
}

#container-homepage {

    position: absolute;
    left: 0;
    top: 0;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;


}

#structure-homepage {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    background-repeat: repeat-y;
    background-size: cover;
    background-position-x: center;
    position: relative;
    height: 100%;
    width: 100%;
    overflow-y: scroll;


}

.section-hp {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 0 80px 0;
}

.fullscreen-section {
    margin: 0;
    min-height: 100%;
    
}

.color-section {
    background-color: #0e1852;
    padding: 70px 0;
}

h2 {
    color: white;
    margin: 0;
}

h3 {
    color: white;
    margin: 0;
}

h4 {
    color: white;
    margin: 0;
}


#container-nav-hp {
    width: calc(100% - 50px);
    display: inline-flex;
    justify-content: space-between;
    padding: 15px 25px;
}

#search-pin-container {
    display: inline-flex;
}

#search-pin-container input {
    font-size: 1rem;
    border: none;
    width: 150px;
    border-radius: 24px 0 0 24px;
    padding: 0 5px 0 20px;
    font-weight: bold;
    text-overflow: ellipsis;
}

#search-pin-container input:focus {
    outline: 1px solid #83a6c5
}
#btn-go{
    padding: 5px !important;
    width: 50px !important;
    border-radius: 0 24px 24px 0 !important;
    background-color: #aea825 !important;
    font-weight: bold !important;
}

#structure-homepage button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 24px;
    border: none;
    padding: 5px 10px 5px 25px;
    font-size: 0.95rem;
    cursor: pointer;
    background-color: white;
    color: black;
}

.enter-icon-hp {
    height: 35px;
    width: 35px;
}



#logo-scrollb > img {
    max-width: 100%;
    max-height: 200px;
}

#title-hp {
    margin: 10px 0 0 0;
}

#container-smartphone {
    flex-grow: 1;
    height: 100%;
    width: 100%;
    display: inline-flex;
    position: relative;
    justify-content: center;
}

#smartphone {
    height: 100%;
    aspect-ratio: 9 / 16;
    position: absolute;
    background-repeat: no-repeat;
    background-size: contain;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.video-scrollb {
    height: 74% !important;
    width: 71% !important;
    object-fit: cover;
    margin-top: 9%;
    background-color: black;
}

.video-scrollb > video {
    object-fit: cover !important;
}

#subtitle-hp {
    margin: 15px 0 0 0;
    max-width: 490px;
    text-align: justify;
    font-weight: normal;
    font-size: 0.9rem;
}

#scan-title {
    margin: 40px 0 30px 0;
    font-size: 0.9rem;
}

#qrcode-homepage {
    height: 120px;
}

.video-title-hp {
    margin: 0 0 15px 0;
}

.separator {
    margin: 25px;
}


#container-schritte {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px 10px;
    margin: 50px 70px 0 70px;
}

.single-schritt {
    color: white;
    flex: 1 1 120px;
}

.s-s-n {
    font-weight: bold;
}

.s-s-t {
    font-weight: 400;
}

.video-title-div {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

#title-faq {
    margin: 0 0 20px 0;
    color: #83a6c5;
}

#container-faq {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 30px 15px;
    justify-content: center;
    margin: 0 70px 0 70px;
}

.faq-item {
    flex: 0 1 450px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    text-align: left;
}

.faq-item > h2 {
    font-size: 1.2rem;
    color: #7589bd;
}

.faq-item > h3 {
    font-size: 1rem;
    font-weight: normal;
}

.faq-item > a {
    color: white;
    font-weight: bold;
    text-decoration: underline;
}

.youtube-embed {
    aspect-ratio: 16/9;
}

.link-beispielbeitrag {
    color: white;
    font-size: 1.1rem;
    font-weight: bold;
    margin: 10px 0 0 0 ;
}

/* Smartphones */
@media only screen and (max-width: 600px) {
    #structure-homepage {
        padding: 0px 10px;
        width: calc(100% - 20px);
    }

    #logo-scrollb {
        margin: 60px 0 0 0;
    }

    #subtitle-hp {
        width: calc(100% - 30px);
    }

    #container-schritte {
        margin: 50px 10px;
    }

    #container-faq {
        margin: 0 5px 0 5px;
        padding: 0 20px;
    }

    #container-nav-hp {
        width: 100%;
        padding: 15px 0;
    }

    #search-pin-container input {
        width: 100px;
    }

 
    .youtube-embed {
        width: 100%;
        height: auto;
    }
    
}

