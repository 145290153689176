#invalidroute-c {
    position: fixed;
    width: 100%;
    top: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 15px;
    background-color: #d8b620;
}

#invalidroute-c p  {
    font-size: 1.3rem;
    font-weight: bold;
}

#invalidroute-c button  {
    font-size: 1.3rem;
    font-weight: bold;
    border: none;
    padding: 10px 15px;
    border-radius: 7px;
    margin: 15px 0 0 0;
    cursor: pointer;
    box-shadow: 2px 2px black;
    -webkit-box-shadow: 2px 2px black;
}

#invalidroute-icon {
    height: 100px;
    width: 100px;
}