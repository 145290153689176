.help-icon {
    background-color: #565656;
    fill: white;
    border-radius: 100%;
    height: 15px;
    width: 15px;
    cursor: pointer;
    margin: 0 0 0 5px;
  }

.tooltip-container {
    position: relative;
}

.tooltip-container:hover .tooltip-text {
    /* display: flex; */
    visibility: visible;
}

.tooltip-text {
    display: flex;
    visibility: hidden;
    position: absolute;
    background-color: #4a4a4a;
    top: 100%;
    left: 0;
    padding: 5px 10px;
    border-radius: 7px;
    z-index: 9999;
    min-width: 160px;
}

.tooltip-text > p {
    font-weight: unset;
    font-size: 1rem;
    color: white;

}

