
.action-panel {
    position: absolute;
    bottom: 100px;
    right: 5px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 2px;
    z-index: 200;
}

/* .... Like .... */
.button-like.active{
    animation: like 0.4s 1;
    -webkit-animation: like 0.4s 1;
    fill: rgb(203, 0, 0);
    stroke:none;
}

.button-like{
    fill: white;
    stroke:none;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
}

@keyframes like {
    0%   { transform: scale(1); }
    90%   { transform: scale(1.2); }
    100% { transform: scale(1); }
}

@-webkit-keyframes like {
    0%   { -webkit-transform: scale(1); }
    90%   { -webkit-transform: scale(1.2); }
    100% { -webkit-transform: scale(1); }
}

/* .... View .... */
.button-view.active{
    animation: like 0.4s 1;
    -webkit-animation: like 0.4s 1;
    fill: rgb(7, 132, 0);
    stroke:none;
}

.button-view{
    fill: white;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
}

/*.... Comment ....*/
.button-comment.active{
    fill: rgb(207, 196, 0);
    stroke:none;
}

.button-comment{
    fill: white;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
    height: 48px;
    width: 48px;
}

.comment-container {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: auto;
}

.comment-input-container {
    
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(255, 255, 255);
}

.my-comments {
    font-size: 0.9rem;
    color: rgb(71, 71, 71);
    margin: 0 65px 10px 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}


.my-comments-item {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    background-color: white;
    width: fit-content;
    border-radius: 5px;
    cursor: pointer;
}

.my-comments-item p {
    padding: 10px 15px;
    border-radius: 5px;
    width: fit-content;
}

.my-comments p:hover {
    background-color: white;
}



.input-question {
    width: calc(100% - 90px);
    height: 40px;
    margin: 10px 5px 10px 0;
    border-radius: 8px;
    border: 0;
    font-size: 1rem;
    padding: 2px 10px;
    background-color: #ebebeb;
    
}

.input-question:focus{
    outline-color: #878686;
    outline-width: 1px;
}

.button-send {
    height: 45px;
    width: 45px;
    fill: #555454;
    cursor: pointer;
}

/* Swipe Up  */
.swipeup {
    position: absolute;
    bottom: 10px;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    z-index: 300;
}

.button-swipeup {
    filter: drop-shadow(1px 1px 5px rgb(0 0 0));
    fill: white;
    transition: fill 0.7s;
    animation: swipeUp 1.5s 3 ease-out;
    
    -webkit-filter: drop-shadow(1px 1px 5px rgb(0 0 0));
    -webkit-transition: fill 0.7s;
    -webkit-animation: swipeUp 1.5s 3 ease-out;
}    

@keyframes swipeUp {
    0% {transform: translateY(0px);}
    30% {transform: translateY(-50px);}
    100% {transform: translateY(0px);}
}

@keyframes swipeup {
    0% {fill: white;}
    50% {fill: #dadada;}
    100% {fill: white;}
}


/* Opening action */
#a-p-start-container {
    position: absolute;
    bottom: 100px;
    z-index: 210;
}

#a-p-start-button {
    background-color: #ffffff;
    padding: 5px 10px;
    border-radius: 7px;
    font-size: 1.8rem;
    cursor: pointer;
    box-shadow: 0px 0px 20px 4px black;
}