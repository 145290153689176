body {
    overscroll-behavior-y: contain;
}


#post-slides-ubersicht {
    display: flex;
    flex-direction: column;
    gap: 2px;
    overflow-y: auto;
    height: 100%;
    max-width: 1024px;
}

.slides-ubersicht-item {
    background-color: white;
    padding: 10px 0px 10px 15px;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
}

.slides-ubersicht-item p {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.enter-icon {
    flex-shrink: 0;
    fill: #636363;
}

