
.sidebar {
    margin: 0px 0 0 0;
    padding: 5px 0 0 0;
    position: fixed;
    width: 80vw;
    height: calc(100% - 55px);
    top: 50px;
    left: -500px;
    z-index: 80;
    
    display: flex;
    gap: 5px;
    flex-direction: column;
    
    background-color: #292929;
    box-shadow: 2px 0px 13px 0px #000000;
    transition: left 0.3s ease-out;
    -webkit-box-shadow: 2px 0px 13px 0px #000000;
    -webkit-transition: left 0.3s ease-out;
}

.sidebar.active {
    left: 0;
}

.sidebar-btn {
    background-color: #2f2f2f;
    height: 50px;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    color: lightgrey;
    font-size: 1.1rem;
    cursor: pointer;
}

#invisble-block-sidebar {
    position: fixed;
    top: 50px;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #00000094;
    z-index: 79;
}



@media only screen and (min-width: 600px) {
    .sidebar {
        width: 250px;
    }
}