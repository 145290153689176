
p {
    margin: 0;
}

#structure {
    position: absolute;
    left: 0;
    top: 0;
    
    box-sizing: border-box;
    
    width: 100%;
    height: 100%;
    
    overflow-x: hidden;
    overflow-y: auto;
}

#structure-snapscroll {
    position: relative;
    height: 100%;
    width: 100%;
    overflow-y: scroll;
    scroll-snap-type: y mandatory;
}

.slide {
    
    position: relative;
    height: 100%;
    width: 100%;
    scroll-snap-align: start;
    scroll-snap-stop: always;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    font-size: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;

}


.slide-vers {
    margin: 0 1em;
    text-align: center;
}

#admin-mode-warning {
    position: fixed;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    background-color: red;
    padding: 2px 5px;
    font-weight: bold;
    font-size: 1.1rem;
    border-radius: 5px;
    color: white;
    box-shadow: 1px 1px black;
    -webkit-box-shadow: 1px 1px black;
    z-index: 9999;

    display: inline-flex;
    align-items: center;
}



